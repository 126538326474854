import Swiper, { Autoplay } from 'swiper';
import 'swiper/css';

const selectors = {
  carouselWrapper: '[data-footer-usps-carousel]',
};

class FooterUspsCarousel extends HTMLElement {
  constructor() {
    super();

    document.addEventListener('DOMContentLoaded', () => {
      this.carouselWrappers = this.querySelectorAll(selectors.carouselWrapper);

      this.render();
    });
  }

  render() {
    // Init the carousel on load.
    this.initCarousels();

    // Subsequently, on resize, we need to check if we need a Swiper object checking the break point.
    // If not, it will get destroyed.
    this.registerEventListeners();
  }

  initCarousels() {
    this.carousel = new Swiper(selectors.carouselWrapper, {
      modules: [Autoplay],
      slidesPerView: 1,
      spaceBetween: 20,
      watchOverflow: true,
      autoplay: true,
      loop: true,
      breakpoints: {
        744: {
          spaceBetween: 30,
          slidesPerView: 2,
        },
        1024: {
          spaceBetween: 30,
          slidesPerView: 3,
        },
        1280: {
          slidesPerView: 4,
        },
      },
    });
  }

  registerEventListeners() {
    window.addEventListener('resize', () => {
      this.carousel.update();
    });
  }
}

customElements.define('footer-usps', FooterUspsCarousel);
